@import '../../../styles/variables.scss';

.listExams {
  &--buttonAdd {
    width: initial !important;
    background-color: transparent;
    border: none;
    color: $secondary;
    font-family: 'Mulish';
    font-weight: 500;
    float: right;
    font-size: 1rem;
    margin-top: 6px;

    &:hover {
      cursor: pointer;
    }
  }

  &--btnSend {
    @media screen and (max-width: 576px) {
      position: fixed;
      bottom: 1rem;
      left: 50%;
      transform: translateX(-50%);
      width: 100%;
      max-width: calc(100% - 2rem);
    }
  }

  &--content {
    @media screen and (min-width: 577px) {
      border: 2px dashed #c5c9d5;
      padding: 2rem;
      border-radius: 0.5rem;
    }
  }
}
