@import '../../../styles/variables.scss';

.button {
  background-color: $secondary;
  border: none;
  padding: 0.8125rem;
  border-radius: 0.5rem;
  color: #fff;
  font-size: 1rem;
  width: 100%;
  display: flex;
  justify-content: center;

  &:focus {
    opacity: 0.5%;
  }

  &:hover {
    cursor: pointer;
  }
}

.c-loader {
  animation: is-rotating 1s infinite;
  border: 6px solid #e5e5e5;
  border-radius: 50%;
  border-top-color: #51d4db;
  height: 25px;
  width: 25px;
}

@keyframes is-rotating {
  to {
    transform: rotate(1turn);
  }
}
