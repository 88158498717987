@import '../../../styles/variables.scss';

.examItem {
  border: 1px solid #d9dce6;
  border-radius: 0.5rem;
  color: $midGrey;
  padding: 0.5rem 1.125rem 0.5rem 0.5rem;
  margin-bottom: 0.625rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  & > div {
    align-items: center;
    display: flex;
  }
  &--imageWrapper {
    background-color: #f0f2f8;
    width: 2rem;
    height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 0.625rem;
    border-radius: 0.25rem;
  }

  &--trashIcon {
    color: $secondary;
    font-size: 20px;
  }

  &--removeItem {
    background-color: transparent;
    border: none;

    &:hover {
      cursor: pointer;
    }
  }

  @media screen and (min-width: 576px) {
    background-color: #f0f2f8;
    border: none;
  }
}
.examImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
