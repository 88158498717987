@import '../../../styles/variables.scss';

.wrapper {
  padding: 1rem;

  width: 100%;
  max-width: 28rem;

  @media screen and (min-width: 576px) {
    text-align: center;
    p {
      font-size: 1.5rem;
      line-height: 2rem;
    }
  }
}

.uploadContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1.5rem;
  border: 2px dashed #c5c9d5;
  border-radius: 0.5rem;
  margin-top: 1.375rem;

  img {
    width: 100%;
    max-width: 4.5rem;
    margin-bottom: 1rem;
  }

  span {
    font-size: 0.875rem;
    margin-bottom: 1.375rem;
  }

  @media screen and (min-width: 576px) {
    margin-top: 4.05rem;

    span {
      font-size: 1.125rem;
    }
  }

  &--button {
    background-color: $secondary;
    border: none;
    padding: 0.8125rem;
    border-radius: 0.5rem;
    color: #fff;
    font-size: 1rem;
    width: 100%;
    text-align: center;

    &:active {
      opacity: 0.5;
    }

    &:hover {
      cursor: pointer;
    }
  }
}

.examsContent {
  margin: 20px 0 10px 0;
  align-self: end;
}

.buttonBack {
  @media screen and (max-width: 576px) {
    position: fixed;
    bottom: 1rem;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    max-width: calc(100% - 2rem);
  }
}
.buttonDownload {
  width: 100%;
  background-color: transparent;
  border: none;
  cursor: pointer;
}
