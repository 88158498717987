@import url(https://fonts.googleapis.com/css2?family=Mulish:wght@300;400;500;600;700;800;900&display=swap);
.wrapper{padding:1rem;width:100%;max-width:28rem}@media screen and (min-width: 576px){.wrapper{text-align:center}.wrapper p{font-size:1.5rem;line-height:2rem}}
.examItem{border:1px solid #d9dce6;border-radius:.5rem;color:#787887;padding:.5rem 1.125rem .5rem .5rem;margin-bottom:.625rem;display:flex;justify-content:space-between;align-items:center}.examItem>div{align-items:center;display:flex}.examItem--imageWrapper{background-color:#f0f2f8;width:2rem;height:2rem;display:flex;align-items:center;justify-content:center;margin-right:.625rem;border-radius:.25rem}.examItem--trashIcon{color:#ff3f72;font-size:20px}.examItem--removeItem{background-color:transparent;border:none}.examItem--removeItem:hover{cursor:pointer}@media screen and (min-width: 576px){.examItem{background-color:#f0f2f8;border:none}}.examImage{width:100%;height:100%;object-fit:cover}
.button{background-color:#ff3f72;border:none;padding:.8125rem;border-radius:.5rem;color:#fff;font-size:1rem;width:100%;display:flex;justify-content:center}.button:focus{opacity:.5%}.button:hover{cursor:pointer}.c-loader{-webkit-animation:is-rotating 1s infinite;animation:is-rotating 1s infinite;border:6px solid #e5e5e5;border-radius:50%;border-top-color:#51d4db;height:25px;width:25px}@-webkit-keyframes is-rotating{to{-webkit-transform:rotate(1turn);transform:rotate(1turn)}}@keyframes is-rotating{to{-webkit-transform:rotate(1turn);transform:rotate(1turn)}}
.container-loading{width:100%;height:100%;position:fixed;left:0;top:0;background-color:rgba(255,255,255,.9);display:flex;flex-direction:column;justify-content:center;align-items:center;z-index:99999}.container-loading h1{font-size:18px;color:var(--color-secondary);margin-top:40px;text-align:center;padding:0 40px}
.modal-container{position:fixed;z-index:1000;width:100vw;height:100vh;top:0;bottom:0;right:0;left:0;background-color:rgba(197,201,213,.6)}.modal-content{width:300px;padding:25px;background:#fff;border-radius:8px;display:flex;flex-direction:column;align-items:start;justify-content:space-between;margin:50px auto}.modal-content span{margin-bottom:1rem;font-size:1rem}.modal-buttons{width:100%;display:flex;justify-content:space-between}
.listExams--buttonAdd{width:auto !important;width:initial !important;background-color:transparent;border:none;color:#ff3f72;font-family:"Mulish";font-weight:500;float:right;font-size:1rem;margin-top:6px}.listExams--buttonAdd:hover{cursor:pointer}@media screen and (max-width: 576px){.listExams--btnSend{position:fixed;bottom:1rem;left:50%;-webkit-transform:translateX(-50%);transform:translateX(-50%);width:100%;max-width:calc(100% - 2rem)}}@media screen and (min-width: 577px){.listExams--content{border:2px dashed #c5c9d5;padding:2rem;border-radius:.5rem}}
.wrapper{padding:1rem;width:100%;max-width:28rem}@media screen and (min-width: 576px){.wrapper{text-align:center}.wrapper p{font-size:1.5rem;line-height:2rem}}.uploadContent{display:flex;flex-direction:column;align-items:center;justify-content:center;padding:1.5rem;border:2px dashed #c5c9d5;border-radius:.5rem;margin-top:1.375rem}.uploadContent img{width:100%;max-width:4.5rem;margin-bottom:1rem}.uploadContent span{font-size:.875rem;margin-bottom:1.375rem}@media screen and (min-width: 576px){.uploadContent{margin-top:4.05rem}.uploadContent span{font-size:1.125rem}}.uploadContent--button{background-color:#ff3f72;border:none;padding:.8125rem;border-radius:.5rem;color:#fff;font-size:1rem;width:100%;text-align:center}.uploadContent--button:active{opacity:.5}.uploadContent--button:hover{cursor:pointer}.examsContent{margin:20px 0 10px 0;align-self:end}@media screen and (max-width: 576px){.buttonBack{position:fixed;bottom:1rem;left:50%;-webkit-transform:translateX(-50%);transform:translateX(-50%);width:100%;max-width:calc(100% - 2rem)}}.buttonDownload{width:100%;background-color:transparent;border:none;cursor:pointer}
.concluded{text-align:center}.concluded--icon{font-size:4rem}
html,body,div,span,applet,object,iframe,h1,h2,h3,h4,h5,h6,p,blockquote,pre,a,abbr,acronym,address,big,cite,code,del,dfn,em,img,ins,kbd,q,s,samp,small,strike,strong,sub,sup,tt,var,b,u,i,center,dl,dt,dd,ol,ul,li,fieldset,form,label,legend,table,caption,tbody,tfoot,thead,tr,th,td,article,aside,canvas,details,embed,figure,figcaption,footer,header,hgroup,menu,nav,output,ruby,section,summary,time,mark,audio,video{margin:0;padding:0;border:0;font-size:100%;font:inherit;vertical-align:baseline;font-family:"Mulish";color:#525166}article,aside,details,figcaption,figure,footer,header,hgroup,menu,nav,section{display:block}body{line-height:1}ol,ul{list-style:none}blockquote,q{quotes:none}blockquote:before,blockquote:after,q:before,q:after{content:"";content:none}table{border-collapse:collapse;border-spacing:0}
*{box-sizing:border-box}p{line-height:1.375rem}.container{padding-right:15px;padding-left:15px;margin-right:auto;margin-left:auto}@media(min-width: 768px){.container{width:750px}}@media(min-width: 992px){.container{width:970px}}@media(min-width: 1200px){.container{width:1170px}}.sr-only{position:absolute;width:1px;height:1px;padding:0;margin:-1px;overflow:hidden;clip:rect(0, 0, 0, 0);border:0}
