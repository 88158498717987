.modal-container {
  position: fixed;
  z-index: 1000;
  width: 100vw;
  height: 100vh;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(197, 201, 213, 0.6);
}

.modal-content {
  width: 300px;
  padding: 25px;
  background: #fff;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-between;
  margin: 50px auto;

  span {
    margin-bottom: 1rem;
    font-size: 1rem;
  }
}

.modal-buttons {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
